import React, { CSSProperties } from "react";

import classNames from 'classnames';

import styles from './MainModule2025.css';
import { Button, Feature, Gradient, Media } from '../../../models/Strapi';
import { Media as MediaResponsive } from '../../../services/MediaService';
import { UrlService } from '../../../services/UrlService';

type IMainComponent = {
  id: number;
  advantageImage: Media;
  sideImage: Media;
  features: Feature[];
  CTA: Button;
  moduleScreenId?: string;
  gradient: Gradient;
  content: string;
};

type MainModuleProps = {
  title: string;
  subTitle: string;
  component: any;
  background?: Media;
  backgroundMobile?: Media;
  backgroundFallback: string;
};

const MainModule2025 = React.memo((props: MainModuleProps) => {
  const {
    title,
    subTitle,
    background,
    backgroundMobile,
    backgroundFallback,
    component: [MainComponent]
  } = props;
  const getBackground = (background: Media, backgroundFallback: string): CSSProperties => {
    const result = {} as CSSProperties;

    if (background) {
      result.backgroundImage = `url(${UrlService.createStrapiMediaUrl(background.url)})`;
    }

    result.backgroundColor = backgroundFallback;
    return result;
  };
  const renderModuleContent = () => {
    return (
      <>
        <div className={styles.leftSide}>
          <img
            className={styles.leftSideImage}
            src={UrlService.createStrapiMediaUrl(MainComponent.sideImage.url)}
            alt={MainComponent.sideImage.alternativeText}
          />
        </div>
        <div className={styles.rightSide}>
          <h1 className={styles.title}>{title}</h1>
          <div className={classNames(styles.text)} dangerouslySetInnerHTML={{ __html: subTitle }} />
          <button className={styles.button} onClick={onClickButton}>{ctaLabel}</button>
        </div>
      </>
    );
  }
  const onClickButton = (): void => {
    const targetModulePositionY = document.getElementById('PricingTariffsModule2025').getBoundingClientRect().y;
    const headerHeight = document.querySelector('header').getBoundingClientRect().height;
    const navigationHeight = document.querySelector('nav').getBoundingClientRect().height;
    const targetScrollPositionY = targetModulePositionY - headerHeight - navigationHeight;

    document.documentElement.scrollBy({
      top: targetScrollPositionY,
      left: 0,
      behavior: 'smooth'
    });
  };
  const ctaLabel = MainComponent.CTA.label;

  return (
    <>
      <MediaResponsive greaterThanOrEqual="ARK_LARGE_MOBILE" style={{ width: '100%' }}>
        <div
          className={classNames(styles.content)}
          style={getBackground(background, backgroundFallback)}
        >
          {renderModuleContent()}
        </div>
      </MediaResponsive>
      <MediaResponsive lessThan="ARK_LARGE_MOBILE_BELOW" style={{ width: '100%' }}>
        <div
          className={classNames(styles.content)}
          style={getBackground(backgroundMobile, backgroundFallback)}
        >
          {renderModuleContent()}
        </div>
      </MediaResponsive>
    </>
  );
});

export default MainModule2025;
