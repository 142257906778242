import React, { CSSProperties } from "react";

import styles from "./ExclusiveGamesModule2025.css";
import { Media } from "../../../models/Strapi";
import { Media as MediaResponsive } from '../../../services/MediaService';
import { UrlService } from "../../../services/UrlService";

interface IExclusiveGamesModule {
	title: string;
	subTitle: string;
	background?: Media;
	backgroundMobile?: Media;
	backgroundFallback: string;
}

const ExclusiveGamesModule2025 = React.memo((props: IExclusiveGamesModule) => {
	const {
		title,
		subTitle,
		background,
		backgroundMobile,
		backgroundFallback
	} = props;
	const getBackground = (background: Media, backgroundFallback: string): CSSProperties => {
		const result = {} as CSSProperties;

		if (background) {
			result.backgroundImage = `url(${UrlService.createStrapiMediaUrl(background.url)})`;
		}

		result.backgroundColor = backgroundFallback;
		return result;
	};
	const renderModuleContent = () => {
		return (
			<>
				<h2 className={styles.title}>{title}</h2>
				<p className={styles.subtitle}>{subTitle}</p>
			</>
		);
	}

	return (
		<>
			<MediaResponsive greaterThanOrEqual="ARK_SMALL_DESKTOP" style={{ width: '100%' }}>
				<div
					className={styles.content}
					style={getBackground(background, backgroundFallback)}
				>
					{renderModuleContent()}
				</div>
			</MediaResponsive>
			<MediaResponsive lessThan="ARK_SMALL_DESKTOP" style={{ width: '100%' }}>
				<div
					className={styles.content}
					style={getBackground(backgroundMobile, backgroundFallback)}
				>
					{renderModuleContent()}
				</div>
			</MediaResponsive>
		</>
	);
});

export default ExclusiveGamesModule2025;
