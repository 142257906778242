import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const PauseIcon = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="pause-icon"
            className={props.className}
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.6667 11.6667C16.2247 11.6667 15.8008 11.8423 15.4882 12.1548C15.1756 12.4674 15 12.8913 15 13.3333V26.6667C15 27.1087 15.1756 27.5326 15.4882 27.8452C15.8008 28.1577 16.2247 28.3333 16.6667 28.3333C17.1087 28.3333 17.5327 28.1577 17.8452 27.8452C18.1578 27.5326 18.3334 27.1087 18.3334 26.6667V13.3333C18.3334 12.8913 18.1578 12.4674 17.8452 12.1548C17.5327 11.8423 17.1087 11.6667 16.6667 11.6667ZM20 3.33333C16.7037 3.33333 13.4814 4.31081 10.7405 6.14217C7.99972 7.97353 5.86352 10.5765 4.60206 13.6219C3.3406 16.6674 3.01054 20.0185 3.65363 23.2515C4.29671 26.4845 5.88406 29.4542 8.21494 31.7851C10.5458 34.116 13.5155 35.7033 16.7485 36.3464C19.9816 36.9895 23.3327 36.6595 26.3781 35.398C29.4235 34.1365 32.0265 32.0003 33.8579 29.2595C35.6892 26.5187 36.6667 23.2964 36.6667 20C36.6667 17.8113 36.2356 15.644 35.398 13.6219C34.5605 11.5998 33.3328 9.76253 31.7852 8.21488C30.2375 6.66724 28.4002 5.43958 26.3781 4.602C24.356 3.76442 22.1887 3.33333 20 3.33333V3.33333ZM20 33.3333C17.363 33.3333 14.7851 32.5513 12.5924 31.0863C10.3998 29.6212 8.69082 27.5388 7.68165 25.1024C6.67249 22.6661 6.40844 19.9852 6.92291 17.3988C7.43738 14.8124 8.70726 12.4366 10.572 10.5719C12.4367 8.7072 14.8124 7.43733 17.3988 6.92286C19.9853 6.40839 22.6661 6.67243 25.1025 7.6816C27.5388 8.69077 29.6212 10.3997 31.0863 12.5924C32.5514 14.785 33.3334 17.3629 33.3334 20C33.3334 23.5362 31.9286 26.9276 29.4281 29.4281C26.9277 31.9286 23.5363 33.3333 20 33.3333V33.3333ZM23.3334 11.6667C22.8914 11.6667 22.4674 11.8423 22.1549 12.1548C21.8423 12.4674 21.6667 12.8913 21.6667 13.3333V26.6667C21.6667 27.1087 21.8423 27.5326 22.1549 27.8452C22.4674 28.1577 22.8914 28.3333 23.3334 28.3333C23.7754 28.3333 24.1993 28.1577 24.5119 27.8452C24.8245 27.5326 25 27.1087 25 26.6667V13.3333C25 12.8913 24.8245 12.4674 24.5119 12.1548C24.1993 11.8423 23.7754 11.6667 23.3334 11.6667Z"
                fill="currentColor"
            />
        </svg>
    );
});
