import React from 'react';

import { iconProps } from '../../models/Icon/IconPropsModel';

export const PlayIcon = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="play-icon"
            className={props.className}
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.6667 17.1166L18.3334 12.3C17.8269 12.0076 17.2524 11.8535 16.6676 11.8534C16.0827 11.8532 15.5082 12.007 15.0015 12.2991C14.4949 12.5913 14.074 13.0116 13.7812 13.5178C13.4884 14.0241 13.334 14.5985 13.3334 15.1833V24.8166C13.334 25.4012 13.4883 25.9753 13.7808 26.4814C14.0733 26.9875 14.4938 27.4077 15 27.7C15.5068 27.9925 16.0816 28.1466 16.6667 28.1466C17.2518 28.1466 17.8267 27.9925 18.3334 27.7L26.6667 22.8833C27.1718 22.5903 27.5911 22.1697 27.8826 21.6637C28.1741 21.1577 28.3275 20.5839 28.3275 20C28.3275 19.416 28.1741 18.8423 27.8826 18.3363C27.5911 17.8302 27.1718 17.4097 26.6667 17.1166ZM25 20L16.6667 24.8166V15.1833L25 20ZM20 3.33331C16.7037 3.33331 13.4814 4.3108 10.7405 6.14215C7.99972 7.97351 5.86352 10.5765 4.60206 13.6219C3.3406 16.6674 3.01054 20.0185 3.65363 23.2515C4.29671 26.4845 5.88406 29.4542 8.21494 31.7851C10.5458 34.116 13.5155 35.7033 16.7485 36.3464C19.9816 36.9895 23.3327 36.6594 26.3781 35.398C29.4235 34.1365 32.0265 32.0003 33.8579 29.2595C35.6892 26.5187 36.6667 23.2963 36.6667 20C36.6667 17.8113 36.2356 15.644 35.398 13.6219C34.5605 11.5998 33.3328 9.76251 31.7852 8.21487C30.2375 6.66722 28.4002 5.43957 26.3781 4.60199C24.356 3.76441 22.1887 3.33331 20 3.33331ZM20 33.3333C17.363 33.3333 14.7851 32.5513 12.5924 31.0862C10.3998 29.6212 8.69082 27.5388 7.68165 25.1024C6.67249 22.6661 6.40844 19.9852 6.92291 17.3988C7.43738 14.8124 8.70726 12.4366 10.572 10.5719C12.4367 8.70719 14.8124 7.43731 17.3988 6.92284C19.9853 6.40837 22.6661 6.67242 25.1025 7.68159C27.5388 8.69075 29.6212 10.3997 31.0863 12.5924C32.5514 14.785 33.3334 17.3629 33.3334 20C33.3334 23.5362 31.9286 26.9276 29.4281 29.4281C26.9277 31.9286 23.5363 33.3333 20 33.3333Z"
                fill="#F9F9F9"
            />
        </svg>
    );
});
