import React from 'react';

import styles from './GamingExperienceModule2025.css';
import { Responsive } from '../../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../../constants/ArkCssBreakpoints';
import { Media } from '../../../models/Strapi';
import { UrlService } from '../../../services/UrlService';

interface IGamingExperienceModule {
  title: string;
  background?: Media;
  backgroundFallback: string;
  component: any;
}

const GamingExperienceModule2025 = React.memo((props: IGamingExperienceModule) => {
  const { title, component: [ImageCardsComponent] } = props;
  const getBackground = ({ background, backgroundFallback }: IGamingExperienceModule) => {
    const result = {} as React.CSSProperties;

    if (background) {
      result.backgroundImage = `url(${UrlService.createStrapiMediaUrl(background.url)})`;
    }

    result.backgroundColor = backgroundFallback;
    return result;
  };

  return (
    <div
      className={styles.content}
      style={getBackground(props)}
    >
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.cardsContainer}>
        {ImageCardsComponent.imageCards?.map(({ title, subtitle, cardImage, cardImageMobile }) => (
          <div className={styles.card} key={title}>
            <div className={styles.cardContent}>
              <h3 className={styles.cardTitle}>{title}</h3>
              <p className={styles.cardSubtitle}>{subtitle}</p>
            </div>
            <Responsive minWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE}>
              <img className={styles.cardImage} src={cardImage.url}
                   alt={cardImage.alternativeText ? cardImage.alternativeText : title} />
            </Responsive>
            <Responsive maxWidth={ArkCssBreakpoints.ARK_LARGE_MOBILE_BELOW}>
              <img className={styles.cardImage} src={cardImageMobile.url}
                   alt={cardImageMobile.alternativeText ? cardImageMobile.alternativeText : title} />
            </Responsive>
          </div>
        ))}
      </div>
    </div>
  );
});

export default GamingExperienceModule2025;
