import React, { Suspense } from 'react';

import { Helmet } from 'react-helmet-async';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { MiscUtils } from '../../utils/MiscUtils';
import { AppLoader } from '../atoms/AppLoader/AppLoader';
import { ErrorBoundary } from '../atoms/ErrorBoundary/ErrorBoundary';
import { environment } from '../config/environment';
import { PageTypes } from '../constants/Pages';
import { CoBrandedPageSEO, PagesData } from '../models/PagesData';
import { MetaInfoService } from '../services/MetaInfoService';
import { PageService } from '../services/PageService';
import { UrlService } from '../services/UrlService';
import { setPageType } from '../store/ducks/pages';
import { P404Template } from '../templates/p404/404Template';
import StrapiGeneratedPage from './StrapiGeneratedPage';

const CoBrandedTemplate = React.lazy(() =>
    MiscUtils.loadableRetry(() => import('../templates/CoBranded/CoBrandedTemplate'), { retries: 3 })
);

type DynamicProps = {
    slug: string;
};

const DynamicPage = React.memo(({ slug }: DynamicProps) => {
    const currentLang = useSelector((state) => state.currentLang);
    const pagesFromStore = useSelector((state) => state.pages);
    const routerFromRedux = useSelector((state) => state.router);
    const strapiPages = useSelector((state) => state.strapiPages);
    const strapiComingSoonPages = useSelector((state) => state.strapiComingSoonPages);

    const dispatch = useDispatch();

    const pages = PagesData.getPages(pagesFromStore);
    const pageSEO = PageService.getPageSEOByPageName(pages, slug) as CoBrandedPageSEO;

    const strapiPageSEO = strapiPages.find((page) => page.name === slug);
    const strapiPage = strapiPages?.find((p) => p.slug === slug) || strapiComingSoonPages?.find((p) => p.slug === slug);

    if (strapiPage) {
        dispatch(setPageType(strapiPage?.pageType || ''));
        return <StrapiGeneratedPage page={strapiPage} />;
    }

    if (!pageSEO || !pageSEO.themeColor) {
        // Check whether co-branded or Strapi page exist
        dispatch(setPageType(PageTypes.NotFound));
        return <P404Template />;
    }

    const url = UrlService.buildAbsoluteUrl(routerFromRedux);
    const enPathname = `/${UrlService.getPageLocalizedRoute('en', PageTypes.Dynamic)}/`;
    const addonLocales = PageService.getNonEnLocales(pageSEO);
    const localesPath = addonLocales.map((item) => ({
        lang: item,
        path: `/${pageSEO.slug[item]}/`,
    }));

    return (
        <>
            <Helmet
                title={pageSEO?.metaHTMLTitle?.[currentLang] || strapiPageSEO?.name || slug}
                link={[
                    { rel: 'canonical', href: `${environment.SITE_BASE_URL}${url}` },
                    ...MetaInfoService.getLinksInfo(enPathname, localesPath),
                ]}
                meta={[
                    { name: 'robots', content: 'noindex, nofollow' },
                    { name: 'twitter:title', content: pageSEO.metaHTMLTitle[currentLang] },
                    { property: 'og:title', content: pageSEO.metaHTMLTitle[currentLang] },
                    {
                        name: 'description',
                        content: pageSEO.onPageDescription[currentLang],
                    },
                    {
                        property: 'og:description',
                        content: pageSEO.onPageDescription[currentLang],
                    },
                    {
                        name: 'twitter:description',
                        content: pageSEO.onPageDescription[currentLang],
                    },
                ]}
            />
            <ErrorBoundary>
                {MiscUtils.isServer ? (
                    <AppLoader fullPage />
                ) : (
                    <Suspense fallback={<AppLoader fullPage />}>
                        <CoBrandedTemplate coBranded={pageSEO as CoBrandedPageSEO} />
                    </Suspense>
                )}
            </ErrorBoundary>
        </>
    );
});

function getSlugFromProps(props: RouteComponentProps<any>) {
    if (!MiscUtils.isServer && window.location.hostname.includes('developers')) {
        return 'developers';
    }
    return props.match.params.slug;
}

// TODO add this correctly and to utils
// function getAppMode () {
//     if (MiscUtils.isServer && window.location.hostname.includes('developers')) {
//         return Enum.Developers
//     }
//     return Enum.Standard;
// }

export default connect((state, props: RouteComponentProps<any>) => ({
    slug: getSlugFromProps(props)
}))(DynamicPage);
