import React, { memo, ReactElement } from "react";

import styles from "./OurMembersModule2025.css";
import { Media } from "../../../models/Strapi";
import { Carousel } from "../../Carousel/Carousel";

interface IQuote {
	quote: string;
	author: string;
	authorAvatar: Media;
}

interface IQuotesComponent {
	__component: string;
	id: number;
	quotes: IQuote[];
}

interface IOurMembersModule {
	title: string;
	subTitle: string;
	component: any;
}

const OurMembersModule2025 = memo((props: IOurMembersModule) => {
	const { title, subTitle, component: [QuotesComponent] } = props;
	const getSlideContent = ({ authorAvatar, quote, author }: IQuote): ReactElement => {
		return (
			<div className={styles.slideContent}>
				<img className={styles.authorAvatar} src={authorAvatar.url} alt={authorAvatar.alternativeText ? authorAvatar.alternativeText : author} />
				<p className={styles.quoteText}>{quote}</p>
				<p className={styles.quoteAuthor}>{author}</p>
			</div>
		);
	};

	return (
		<div
			className={styles.content}
		>
			<h2 className={styles.title}>{title}</h2>
			<p className={styles.subtitle}>{subTitle}</p>
			<Carousel
				slides={QuotesComponent.quotes}
				getSlideContent={getSlideContent}
				slideClassName={styles.quoteSlide}
				paginationClassName={styles.pagination}
			/>
		</div>
	);
});

export default OurMembersModule2025;
