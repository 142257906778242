import React, { FC, ReactElement, useMemo, useRef, useState } from "react";

import classNames from 'classnames';
import SwiperCore from 'swiper';
import { Autoplay, A11y } from "swiper/modules";
import { SwiperSlide, Swiper } from 'swiper/react';

import styles from './Carousel.css';
import { HOMEPAGE_CAROUSEL_DELAY } from '../../constants/HomepageCarousel';
import { PauseIcon } from '../../FigmaStyleguide/Icons/PauseIcon';
import { PlayIcon } from '../../FigmaStyleguide/Icons/PlayIcon';

interface ICarousel {
	slides: any;
	getSlideContent: (slide) => ReactElement;
	slideClassName?: string;
	paginationClassName?: string;
}

export const Carousel: FC<ICarousel> = (props) => {
	const { slides, getSlideContent, slideClassName, paginationClassName } = props;
	const [sliderPlaying, setSliderPlaying] = useState(true);
	const [activeIndex, setActiveIndex] = useState(0);
	const swiperRef = useRef<SwiperCore>();
	const pauseHandler = () => {
		swiperRef.current.autoplay.running ? swiperRef.current.autoplay.stop() : swiperRef.current.autoplay.start();
		setSliderPlaying(swiperRef.current.autoplay.running);
	};
	// Memoize the rendered slides to prevent unnecessary re-renders
	const renderedSlides = useMemo(
		() =>
			slides.map((item, i) => (
				<SwiperSlide key={'swiper-slide-' + i}>
					<div
						className={classNames(styles.slide, { [slideClassName]: slideClassName })}
					>
						{getSlideContent(item)}
					</div>
				</SwiperSlide>
			)),
		[slides]
	);
	// Memoize the pagination to prevent unnecessary re-renders
	const paginationButtons = useMemo(
		() =>
			slides.map((_, i) => (
				<button
					key={'pagination-item-' + i}
					type="button"
					className={classNames(styles.pageButton, {
						[styles.active]: i === activeIndex,
					})}
					onClick={() => {
						if (i !== activeIndex) {
							swiperRef.current.slideTo(i);
						}
					}}
					aria-label={`Go to slide number ${i + 1}`}
				/>
			)),
		[slides, activeIndex]
	);

	return (
		<Swiper
			onSwiper={(swiper) => {
				swiperRef.current = swiper;
			}}
			slidesPerView={1}
			onActiveIndexChange={(swiper) => {
				setActiveIndex(swiper.activeIndex);
			}}
			modules={[Autoplay, A11y]}
			autoplay={{ delay: HOMEPAGE_CAROUSEL_DELAY, disableOnInteraction: true }}
			a11y={{ enabled: true }}
			className={classNames(styles.sliderContainer, { [slideClassName]: slideClassName })}
			onAutoplayStart={() => setSliderPlaying(true)}
			onAutoplayStop={() => setSliderPlaying(false)}
		>
			{renderedSlides}
			<div className={classNames(styles.pagination, paginationClassName && paginationClassName)}>
				<button
					type="button"
					onClick={pauseHandler}
					className={styles.handlerBtn}
					aria-label={sliderPlaying ? 'Pause slider' : 'Autoplay slider'}
				>
					{sliderPlaying ? <PauseIcon /> : <PlayIcon />}
				</button>
				{paginationButtons}
			</div>
		</Swiper>
	);
};
